import { CircleStatus } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Grid2, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Billing } from '../../components/invoice/Billing';
import {
  BookingFormSchemaType,
  NewTokenFormSchemaType,
  baseFormSchema,
  bookingDefaultValues,
  bookingFormSchema,
  gpsDefaultValues,
  gpsFormSchema,
  newTokenDefaultValues,
} from '../../components/invoice/InvoiceUpdateForm';
import { RentalInformations } from '../../components/invoice/RentalInformations';
import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, VamMoreOptions, VamPageTitle } from '../../components/shared';
import { useAppSelector } from '../../hooks';
import { AxiosInvoiceDetails, InvoiceUpdate } from '../../interfaces/invoice';
import { updateInvoice } from '../../services/api/invoice-api';
import { selectUser } from '../../services/store/userSlice';
import { $lightBlue } from '../../styles/colors';
import { INVOICE_STATUS_COLORS, InvoiceStatus, InvoiceType } from '../../utils/constants/invoice';
import { formatDate } from '../../utils/functions';
import { redirectTo } from '../../utils/functions/helpers';
import { pipeInvoiceDetailsFields } from './utils';

export default function InvoiceDetails() {
  const params = useParams();
  const { data } = useLoaderData() as AxiosInvoiceDetails;
  const dataInvoice = pipeInvoiceDetailsFields(data);
  const { t } = useTranslation('invoice');
  const { t: translateCommon } = useTranslation('common');
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const navigate = useNavigate();
  let defaultValues;
  let formSchema;
  switch (dataInvoice?.invoice?.invoice_type) {
    case InvoiceType.NEW_TOKEN:
      defaultValues = newTokenDefaultValues;
      formSchema = baseFormSchema;
      break;
    case InvoiceType.BOOKING:
      defaultValues = bookingDefaultValues;
      formSchema = bookingFormSchema;
      break;
    case InvoiceType.GPS:
      defaultValues = gpsDefaultValues;
      formSchema = gpsFormSchema;
      break;
  }

  const methods = useForm<BookingFormSchemaType | NewTokenFormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });

  const { reset, handleSubmit, getValues, formState } = methods;

  React.useEffect(() => {
    reset({
      ...dataInvoice.invoice,
      reservation_amount: getValues('reservation_amount'),
      awb_number_dispatch: dataInvoice.invoice.awb_number_dispatch || '',
      awb_number_return: dataInvoice.invoice.awb_number_return || '',
      dispatch_date: dataInvoice.invoice.dispatch_date
        ? formatDate(dataInvoice.invoice.dispatch_date, false)
        : '-',
      return_date: dataInvoice.invoice.return_date
        ? formatDate(dataInvoice.invoice.return_date, false)
        : '-',
      price: getValues('price'),
    } as any);
  }, [data]);

  /* istanbul ignore next */
  const modifyInvoice: SubmitHandler<BookingFormSchemaType | NewTokenFormSchemaType> = (data) => {
    const invoice: InvoiceUpdate = {
      ...data,
      booking_id: dataInvoice.invoice.booking_id,
      booking_status: dataInvoice.invoice.booking_status,
      invoice_id: dataInvoice.invoice.invoice_id,
      invoice_type: dataInvoice.invoice.invoice_type,
      licensee_number: dataInvoice.invoice.licensee_number,
      user_id: user.idUserMyVam,
      modified_by: `${user.lastName} ${user.firstName}`,
      dispatch_shipping_id: dataInvoice.invoice.dispatch_shipping_id,
      return_shipping_id: dataInvoice.invoice.return_shipping_id,
    };
    /* istanbul ignore next */
    updateInvoice(parseInt(params.invoice_id, 10), invoice)
      .then(() => {
        enqueueSnackbar(translateCommon('notification.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        redirectTo(`../`, navigate, {
          state: { from: location },
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(translateCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('invoiceDetails.tag')}
              title={t('invoiceDetails.title')}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Invoice listing',
                  path: '../',
                },
                {
                  breadcrumb: 'Invoice details',
                  path: '#',
                },
              ]}
              detailsLabelBox={[
                {
                  label: `#${dataInvoice?.invoice?.invoice_id}`,
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CircleStatus
              color={INVOICE_STATUS_COLORS[dataInvoice?.invoice?.invoice_status]}
              label={t(`invoiceListing.invoiceStatus.${dataInvoice?.invoice?.invoice_status}`)}
            />
            <VamMoreOptions options={[]} data-testid="vam-menu-detail-actions" />
            <CtaLoadingButton
              buttonText={t('invoiceDetails.submitBtn')}
              type="submit"
              variant="cta"
              data-testid="submit-update-invoice-btn"
              startIcon={<Check />}
              onClick={handleSubmit(modifyInvoice)}
              disabled={
                !formState.isValid ||
                dataInvoice.invoice.invoice_status !== InvoiceStatus.TO_BE_TREATED
              }
            />
          </Stack>
        </Stack>

        <Grid2
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid2 size={6} data-testid="rental-informations-section">
            <RentalInformations invoice={dataInvoice?.invoice} licensee={dataInvoice?.licensee} />
          </Grid2>
          <Grid2 size={6} data-testid="billing-section">
            <Billing invoice={dataInvoice?.invoice} />
          </Grid2>
        </Grid2>
      </FormProvider>
    </ContainerOutlet>
  );
}
