import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import { TextField, TextFieldProps, TypographyVariant } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { $grey10, $primaryWhite } from '../../../styles/colors';
import { TextFieldVariant } from '../../../utils';
import { getError } from './utils';

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
  name: string;
  label?: string | ReactElement;
  variant?: string;
  requiredMessage?: string;
  patternValue?: RegExp;
  patternMessage?: string;
  withFixedLabel?: boolean;
  labelVariant?: TypographyVariant;
  labelValue?: string;
  handleChange?: Function;
  isUppercase?: boolean;
  inputDataTestId?: string;
  errorMessage?: string;
} & TextFieldProps;

const StyleFixedLabel = styled(Typo)`
  line-height: 1rem;
`;

const StyledTextField = styled(TextField)`
  background: ${$primaryWhite};
  box-shadow: 0 3px 2px -2px ${$grey10};
  display: flex;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  margin-top: 5px;

  .MuiInput-root {
    font-style: italic;
    font-size: 14px;
    padding: 8px;
    display: flex;
    &:before {
      border-bottom: none;
    }
    &:after {
      border-bottom: none;
    }
    &:focus {
      border-bottom: none;
    }
    &:hover {
      &:not(.Mui-disabled, .Mui-error) {
        &:before {
          border-bottom: none;
        }
      }
    }
  }
`;

export const FormTextField: FC<FormInputProps> = ({
  name,
  label,
  variant,
  requiredMessage,
  patternValue,
  patternMessage,
  withFixedLabel,
  labelValue,
  labelVariant,
  handleChange,
  isUppercase,
  inputDataTestId,
  errorMessage,
  ...otherProps
}) => {
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const Body = withFixedLabel ? StyledTextField : TextField;

  /* istanbul ignore next */
  const onChangeTextField = (e: any) => {
    if (handleChange) {
      handleChange(e);
    }
    return e;
  };

  const error = getError(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: requiredMessage,
        pattern: { value: patternValue, message: patternMessage },
      }}
      render={({ field: { onChange, value } }) => (
        <>
          {withFixedLabel ? (
            <StyleFixedLabel
              component="label"
              fontWeight={400}
              data-testid="form-text-field-label"
              variant={labelVariant || 'overline'}
            >
              {labelValue}
            </StyleFixedLabel>
          ) : null}
          <Body
            autoComplete="off"
            inputProps={{ 'data-testid': inputDataTestId || 'form-text-field-input' }}
            onChange={(e) => {
              onChange(e);
              onChangeTextField(e);
            }}
            value={isUppercase ? value?.toUpperCase() : value}
            fullWidth={!withFixedLabel}
            label={label}
            variant={variant || TextFieldVariant.Standard}
            error={!!error}
            helperText={error ? error.message : ''}
            size={withFixedLabel ? 'small' : otherProps.size}
            {...otherProps}
          />
        </>
      )}
    />
  );
};
