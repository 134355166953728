import { TypeOf, boolean, date, number, object, string } from 'zod';

import { REQUIRED_DATE_FIELD, REQUIRED_FIELD, RentalType } from '../../utils';

export const baseFormSchema = object({
  admin_fees: number().min(0, REQUIRED_FIELD),
  comments: string().optional().nullable(),
  free_of_charges: boolean().nullable(),
  price: number().min(0, REQUIRED_FIELD),
});

export const bookingFormSchema = baseFormSchema.extend({
  admin_charges: number().min(0, REQUIRED_FIELD),
  bident_price: number().min(0, REQUIRED_FIELD),
  damage_surcharge: number().min(0, REQUIRED_FIELD),
  days_in_hiring: number().min(0, REQUIRED_FIELD),
  end_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  export_transport_charges: number().min(0, REQUIRED_FIELD),
  export_transport_zone: number().min(0, REQUIRED_FIELD),
  export_weight: number().min(0, REQUIRED_FIELD),
  fuel_charges: number().min(0, REQUIRED_FIELD),
  import_transport_charges: number().min(0, REQUIRED_FIELD),
  import_transport_zone: number().optional().nullable(),
  import_weight: number().optional().nullable(),
  missing_gauge_surcharge: number().min(0, REQUIRED_FIELD),
  price_per_day: number().min(0, REQUIRED_FIELD),
  rental_type: string().min(1, REQUIRED_FIELD),
  reservation_amount: number().nullable(),
  start_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  switched_to_ltr: boolean().nullable(),
  awb_number_dispatch: string().optional().nullable(),
  awb_number_return: string().optional().nullable(),
  dispatch_date: string().optional().nullable(),
  return_date: string().optional().nullable(),
});

export const gpsFormSchema = baseFormSchema.extend({
  days_in_hiring: number().min(0, REQUIRED_FIELD),
  end_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  fuel_charges: number().min(0, REQUIRED_FIELD),
  price_per_day: number().min(0, REQUIRED_FIELD),
  rental_type: string().min(1, REQUIRED_FIELD),
  reservation_amount: number().nullable(),
  start_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
});

// TODO : royaltiesFormSchema

export const newTokenDefaultValues: NewTokenFormSchemaType = {
  admin_fees: 0,
  comments: '',
  free_of_charges: false,
  price: 0,
};

export const bookingDefaultValues: BookingFormSchemaType = {
  ...newTokenDefaultValues,
  admin_charges: 0,
  bident_price: 0,
  damage_surcharge: 0,
  days_in_hiring: 0,
  end_date: null as any,
  export_transport_charges: 0,
  export_transport_zone: 0,
  export_weight: 0,
  fuel_charges: 0,
  import_transport_charges: 0,
  import_transport_zone: 0,
  import_weight: 0,
  missing_gauge_surcharge: 0,
  price_per_day: 0,
  rental_type: null as any,
  reservation_amount: 0,
  start_date: null as any,
  switched_to_ltr: false,
  awb_number_dispatch: '',
  awb_number_return: '',
};

export const gpsDefaultValues: GpsFormSchemaType = {
  days_in_hiring: 0,
  end_date: null as any,
  fuel_charges: 0,
  price_per_day: 0,
  rental_type: RentalType.Short,
  reservation_amount: 0,
  start_date: null as any,
};

// TODO : royaltiesDefaultValues

export type BookingFormSchemaType = TypeOf<typeof bookingFormSchema>;
export type NewTokenFormSchemaType = TypeOf<typeof baseFormSchema>;
export type GpsFormSchemaType = TypeOf<typeof gpsFormSchema>;

// TODO : RoyaltiesFormSchemaType
